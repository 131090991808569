<template>
    <router-link tag="div" :to="`/groupSession/${notification.groupSession.JID}`" v-if="notification !== null">
        <div class="max-w-sm w-full bg-white shadow-lg rounded-lg pointer-events-auto cursor-pointer">
            <div class="rounded-lg shadow-xs">
                <div class="p-4">
                    <div class="flex">
                        <div class="flex-shrink-0 pt-0.5">
                            <img class="h-10 w-10 rounded-full" :src="notification.initiator.avatarUrl" alt="">
                        </div>
                        <div class="ml-3 w-0 flex-1 flex items-center">
                            <p class="text-sm leading-5 font-medium text-gray-900">
                                Presenter Invitation
                            </p>
                        </div>
                        <div class="ml-4 flex-shrink-0 flex">
                            <button @click.stop="markRead" class="inline-flex text-gray-400 focus:outline-none focus:text-gray-500 transition ease-in-out duration-150">
                                <!-- Heroicon name: x -->
                                <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                    <path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd" />
                                </svg>
                            </button>
                        </div>
                    </div>
                    <div>
                        <p class="mt-2 ml-1 text-sm leading-5 text-gray-500">
                            You are invited to present in a group session
                            <span class="font-medium text-gray-700">About:</span> {{ notification.groupSession.topic }}
                            <br>
                            <span class="font-medium text-gray-700">From:</span> {{ initiatorFullName }}
                            <br>
                            <span class="font-medium text-gray-700">When:</span> {{ timeDateFromInput(notification.groupSession.startsAt) }}
                        </p>
                        <div class="mt-4 flex">
                                <span class="inline-flex rounded-md shadow-sm">
                                    <button @click.stop="attendGroupSession" type="button" class="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition ease-in-out duration-150">
                                      Attend
                                    </button>
                                </span>
                            <span class="ml-3 inline-flex rounded-md shadow-sm">
                                    <button @click.stop="declineInvitation" type="button" class="inline-flex items-center px-3 py-2 border border-gray-300 text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:text-gray-800 active:bg-gray-50 transition ease-in-out duration-150">
                                      Decline
                                    </button>
                                </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </router-link>
</template>

<script>
    import {jigo} from '@/axios-auth'
    import * as fb from '../../firebaseConfig'
    import {timeDateFromInput} from '@/utils/helpers'

    export default {

        name: "GroupSessionInvitationNotification",
        props:['notification'],
        computed: {
            initiatorFullName(){
                return `${this.notification.initiator.firstName} ${this.notification.initiator.lastName}`
            },
        },
        methods: {
            timeDateFromInput,
            markRead(){
                fb.db.collection("notifications").doc(fb.auth.currentUser.uid)
                .collection("notifications").doc(this.notification.JID).update({
                    read: true
                })
            },
            declineInvitation(){
                const notificationDoc = fb.db.collection("notifications")
                .doc(fb.auth.currentUser.uid).collection("notifications")
                .doc(this.notification.JID)
                notificationDoc.update({read: true})
                .then()
                .catch((err) => {
                    console.error("ERROR: updating the notification in declineCoLearnership FAILED", err)
                })
                jigo.delete(`/v2/users/${this.notification.requester.username}/coLearning/declineInvitation`)
                .then((resp) => {
                    if(resp.status !== 200) {
                        console.error("ERROR: unwanted status returned", resp.status)
                        notificationDoc.update({read: false})
                    }
                    // notificationDoc.delete()
                })
                .catch((err) => {
                    notificationDoc.update({read: false})
                    console.error("ERROR: declineCoLearnership FAILED", err)
                })
            },
            attendGroupSession(){
                const notificationDoc = fb.db.collection("notifications").doc(fb.auth.currentUser.uid)
                .collection("notifications")
                .doc(this.notification.JID)
                notificationDoc.update({read: true})
                .then()
                .catch((err) => {
                    console.error("ERROR: updating the notification in acceptCoLearnership FAILED", err)
                })
                jigo.put(`/v2/groupSession/${this.notification.groupSession.JID}/attend`)
                .then((resp) => {
                    if(resp.status !== 200) {
                        console.error("ERROR: unwanted status returned", resp.status)
                        notificationDoc.update({read: false})
                    }
                    // notificationDoc.delete()
                })
                .catch((err) => {
                    notificationDoc.update({read: false})
                    console.error("ERROR: attendGroupSession FAILED", err)
                })
            },
            goToUser(){
            }
        }

    }
</script>

<style scoped>

</style>
