<template>
    <div class="h-full bg-jiruto-main fixed flex overflow-hidden">
        <!-- Off-canvas menu for mobile -->
        <div class="lg:hidden">
            <div class="fixed inset-0 flex z-40">
                <!--
                  Off-canvas menu overlay, show/hide based on off-canvas menu state.

                  Entering: "transition-opacity ease-linear duration-300"
                    From: "opacity-0"
                    To: "opacity-100"
                  Leaving: "transition-opacity ease-linear duration-300"
                    From: "opacity-100"
                    To: "opacity-0"
                -->
                <transition enter-class="opacity-0"
                            enter-active-class="transition-opacity ease-linear duration-300"
                            enter-to-class="opacity-100"
                            leave-class="opacity-100"
                            leave-active-class="transition-opacity ease-linear duration-300"
                            leave-to-class="opacity-0">

                    <div v-if="showMenu" class="fixed inset-0">
                        <div class="absolute inset-0 bg-cool-gray-600 opacity-75"></div>
                    </div>

                </transition>

                <!--
                  Off-canvas menu, show/hide based on off-canvas menu state.

                  Entering: "transition ease-in-out duration-300 transform"
                    From: "-translate-x-full"
                    To: "translate-x-0"
                  Leaving: "transition ease-in-out duration-300 transform"
                    From: "translate-x-0"
                    To: "-translate-x-full"
                -->

                <transition enter-class="-translate-x-full"
                            enter-active-class="transition ease-in-out duration-300 transform"
                            enter-to-class="translate-x-0"
                            leave-class="translate-x-0"
                            leave-active-class="transition ease-in-out duration-300 transform"
                            leave-to-class="-translate-x-full">

                    <div v-if="showMenu" class="relative flex-1 flex flex-col max-w-xs w-full pt-5 pb-4 bg-jiruto-menu">
                        <div class="absolute top-0 right-0 -mr-14 p-1">
                            <button class="flex items-center justify-center h-12 w-12 rounded-full focus:outline-none focus:bg-cool-gray-600" aria-label="Close sidebar">
                                <svg class="h-6 w-6 text-white" stroke="currentColor" fill="none" viewBox="0 0 24 24">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                                </svg>
                            </button>
                        </div>
                        <div class="flex-shrink-0 flex items-center px-4">
                            <img class="h-8 w-auto" :src="$store.getters.getAvatarUrl" alt="user logo">
                        </div>
                        <div class="mt-5 overflow-y-auto">
                            <nav class="px-2 space-y-1">
                                <a href="#" class="group flex items-center px-2 py-2 text-base leading-6 font-medium rounded-md text-white bg-teal-700 focus:outline-none focus:bg-teal-500 transition ease-in-out duration-150">
                                    <svg class="mr-4 h-6 w-6 text-teal-200 transition ease-in-out duration-150" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6" />
                                    </svg>
                                    Home
                                </a>

                                <!--                            <a href="#" class="group flex items-center px-2 py-2 text-base leading-6 font-medium rounded-md text-teal-100 hover:text-white hover:bg-teal-500 focus:outline-none focus:bg-teal-500 transition ease-in-out duration-150">-->
                                <!--                                <svg class="mr-4 h-6 w-6 text-teal-200 transition ease-in-out duration-150" fill="none" viewBox="0 0 24 24" stroke="currentColor">-->
                                <!--                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z" />-->
                                <!--                                </svg>-->
                                <!--                                History-->
                                <!--                            </a>-->

                                <!--                            <a href="#" class="group flex items-center px-2 py-2 text-base leading-6 font-medium rounded-md text-teal-100 hover:text-white hover:bg-teal-500 focus:outline-none focus:bg-teal-500 transition ease-in-out duration-150">-->
                                <!--                                <svg class="mr-4 h-6 w-6 text-teal-200 transition ease-in-out duration-150" fill="none" viewBox="0 0 24 24" stroke="currentColor">-->
                                <!--                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 6l3 1m0 0l-3 9a5.002 5.002 0 006.001 0M6 7l3 9M6 7l6-2m6 2l3-1m-3 1l-3 9a5.002 5.002 0 006.001 0M18 7l3 9m-3-9l-6-2m0-2v2m0 16V5m0 16H9m3 0h3" />-->
                                <!--                                </svg>-->
                                <!--                                Balances-->
                                <!--                            </a>-->

                                <a href="#" class="group flex items-center px-2 py-2 text-base leading-6 font-medium rounded-md text-teal-100 hover:text-white hover:bg-teal-500 focus:outline-none focus:bg-teal-500 transition ease-in-out duration-150">
                                    <svg class="mr-4 h-6 w-6 text-teal-200 transition ease-in-out duration-150" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 10h18M7 15h1m4 0h1m-7 4h12a3 3 0 003-3V8a3 3 0 00-3-3H6a3 3 0 00-3 3v8a3 3 0 003 3z" />
                                    </svg>
                                    Cards
                                </a>

                                <a href="#" class="group flex items-center px-2 py-2 text-base leading-6 font-medium rounded-md text-teal-100 hover:text-white hover:bg-teal-500 focus:outline-none focus:bg-teal-500 transition ease-in-out duration-150">
                                    <svg class="mr-4 h-6 w-6 text-teal-200 transition ease-in-out duration-150" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z" />
                                    </svg>
                                    Recipients
                                </a>

                                <a href="#" class="group flex items-center px-2 py-2 text-base leading-6 font-medium rounded-md text-teal-100 hover:text-white hover:bg-teal-500 focus:outline-none focus:bg-teal-500 transition ease-in-out duration-150">
                                    <svg class="mr-4 h-6 w-6 text-teal-200 transition ease-in-out duration-150" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 17v-2m3 2v-4m3 4v-6m2 10H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
                                    </svg>
                                    Reports
                                </a>
                            </nav>
                        </div>
                        <hr class="h-px mt-6 bg-teal-700 border-none">
                        <div class="mt-6 flex-1 h-0 overflow-y-auto">
                            <nav class="px-2 space-y-1">
                                <a href="#" class="group flex items-center px-2 py-2 text-base leading-6 font-medium rounded-md text-teal-100 hover:text-white hover:bg-teal-500 focus:outline-none focus:bg-teal-500 transition ease-in-out duration-150">
                                    <svg class="mr-4 h-6 w-6 text-teal-200 group-hover:text-teal-200 group-focus:text-teal-200 transition ease-in-out duration-150" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z" />
                                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                                    </svg>
                                    Settings
                                </a>

                                <a href="#" class="group flex items-center px-2 py-2 text-base leading-6 font-medium rounded-md text-teal-100 hover:text-white hover:bg-teal-500 focus:outline-none focus:bg-teal-500 transition ease-in-out duration-150">
                                    <svg class="mr-4 h-6 w-6 text-teal-300 group-hover:text-teal-200 group-focus:text-teal-200 transition ease-in-out duration-150" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8.228 9c.549-1.165 2.03-2 3.772-2 2.21 0 4 1.343 4 3 0 1.4-1.278 2.575-3.006 2.907-.542.104-.994.54-.994 1.093m0 3h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                    </svg>
                                    Help
                                </a>

                                <a href="#" class="group flex items-center px-2 py-2 text-base leading-6 font-medium rounded-md text-teal-100 hover:text-white hover:bg-teal-500 focus:outline-none focus:bg-teal-500 transition ease-in-out duration-150">
                                    <svg class="mr-4 h-6 w-6 text-teal-300 group-hover:text-teal-200 group-focus:text-teal-200 transition ease-in-out duration-150" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12l2 2 4-4m5.618-4.016A11.955 11.955 0 0112 2.944a11.955 11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016z" />
                                    </svg>
                                    Privacy
                                </a>
                            </nav>
                        </div>
                    </div>

                </transition>



                <div class="flex-shrink-0 w-14">
                    <!-- Dummy element to force sidebar to shrink to fit close icon -->
                </div>
            </div>
        </div>

        <!-- Static sidebar for desktop -->
        <div class="hidden lg:flex lg:flex-shrink-0">
            <div class="flex flex-col w-64">
                <!-- Sidebar component, swap this element with another sidebar if you like -->
                <div class="flex flex-col flex-grow bg-jiruto-main pt-5 pb-4 overflow-y-auto">
                    <div class="flex items-center flex-shrink-0 px-4">
                        <div>
                            <button @click="goToProfile" class="max-w-xs flex items-center text-md rounded-full focus:outline-none lg:p-2 lg:rounded-md" id="user-menu" aria-label="User menu" aria-haspopup="true">
                                <img class="h-12 w-12 rounded-full bg-gray-400" :src="$store.getters.getAvatarUrl" alt="">
                                <p class="hidden ml-3 text-gray-300 text-md leading-5 font-medium lg:block">{{ $store.getters.getFullName }}</p>
                            </button>
                        </div>
                    </div>
                    <div class="mt-5 flex-1 flex flex-col overflow-y-auto ml-5">
                        <div class="overflow-y-auto">
                            <nav class="px-2 space-y-1">
                                <router-link tag="a" to="/my/dashboard" exact class="group flex items-center px-2 py-2 text-sm leading-6 font-medium rounded-md text-gray-200 hover:text-white hover:bg-jiruto-zotSecondary focus:outline-none focus:bg-jiruto-zotSecondary active:bg-jiruto-zotSeconday transition ease-in-out duration-100">
                                    <svg class="mr-4 h-6 w-6 text-jiruto-zotSecondary group-hover:text-jiruto-zotDarker group-focus:text-jiruto-zotDarker transition ease-in-out duration-150" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6" />
                                    </svg>
                                    Dashboard
                                </router-link>

<!--                                <a href="#" class="group flex items-center px-2 py-2 text-sm leading-6 font-medium rounded-md text-gray-200 hover:text-white hover:bg-jiruto-zotSecondary focus:outline-none focus:bg-jiruto-zotSecondary transition ease-in-out duration-150">-->
<!--                                    <svg class="mr-4 h-6 w-6 text-jiruto-zotSecondary group-hover:text-jiruto-zotDarker group-focus:text-jiruto-zotDarker transition ease-in-out duration-150" fill="none" viewBox="0 0 24 24" stroke="currentColor">-->
<!--                                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z" />-->
<!--                                    </svg>-->
<!--                                    History-->
<!--                                </a>-->

<!--                                <a href="#" class="group flex items-center px-2 py-2 text-sm leading-6 font-medium rounded-md text-gray-200 hover:text-white hover:bg-jiruto-zotSecondary focus:outline-none focus:bg-jiruto-zotSecondary transition ease-in-out duration-150">-->
<!--                                    <svg class="mr-4 h-6 w-6 text-jiruto-zotSecondary group-hover:text-jiruto-zotDarker group-focus:text-jiruto-zotDarker transition ease-in-out duration-150" fill="none" viewBox="0 0 24 24" stroke="currentColor">-->
<!--                                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 6l3 1m0 0l-3 9a5.002 5.002 0 006.001 0M6 7l3 9M6 7l6-2m6 2l3-1m-3 1l-3 9a5.002 5.002 0 006.001 0M18 7l3 9m-3-9l-6-2m0-2v2m0 16V5m0 16H9m3 0h3" />-->
<!--                                    </svg>-->
<!--                                    Balances-->
<!--                                </a>-->

                                <router-link to="/my/savedItems" tag="a" class="group flex items-center px-2 py-2 text-sm leading-6 font-medium rounded-md text-gray-200 hover:text-white hover:bg-jiruto-zotSecondary focus:outline-none focus:bg-jiruto-zotSecondary transition ease-in-out duration-100">
                                    <svg class="mr-4 h-6 w-6 text-jiruto-zotSecondary group-hover:text-jiruto-zotDarker group-focus:text-jiruto-zotDarker transition ease-in-out duration-150" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 10h18M7 15h1m4 0h1m-7 4h12a3 3 0 003-3V8a3 3 0 00-3-3H6a3 3 0 00-3 3v8a3 3 0 003 3z" />
                                    </svg>
                                    Saved Items
                                </router-link>

                                <router-link to="/my/calendar" tag="a" class="group flex items-center px-2 py-2 text-sm leading-6 font-medium rounded-md text-gray-200 hover:text-white hover:bg-jiruto-zotSecondary focus:outline-none focus:bg-jiruto-zotSecondary transition ease-in-out duration-100">
                                    <svg class="mr-4 h-6 w-6 text-jiruto-zotSecondary group-hover:text-jiruto-zotDarker group-focus:text-jiruto-zotDarker transition ease-in-out duration-150" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 10h18M7 15h1m4 0h1m-7 4h12a3 3 0 003-3V8a3 3 0 00-3-3H6a3 3 0 00-3 3v8a3 3 0 003 3z" />
                                    </svg>
                                    Calendar
                                </router-link>

                                <router-link to="/my/groupSessions" tag="a" class="group flex items-center px-2 py-2 text-sm leading-6 font-medium rounded-md text-gray-200 hover:text-white hover:bg-jiruto-zotSecondary focus:outline-none focus:bg-jiruto-zotSecondary transition ease-in-out duration-100">
                                    <svg class="mr-4 h-6 w-6 text-jiruto-zotSecondary group-hover:text-jiruto-zotDarker group-focus:text-jiruto-zotDarker transition ease-in-out duration-150" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z" />
                                    </svg>
                                    Group Sessions
                                </router-link>

                                <a href="#" class="group flex items-center px-2 py-2 text-sm leading-6 font-medium rounded-md text-gray-200 hover:text-white hover:bg-jiruto-zotSecondary focus:outline-none focus:bg-jiruto-zotSecondary transition ease-in-out duration-100">
                                    <svg class="mr-4 h-6 w-6 text-jiruto-zotSecondary group-hover:text-jiruto-zotDarker group-focus:text-jiruto-zotDarker transition ease-in-out duration-150" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 17v-2m3 2v-4m3 4v-6m2 10H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
                                    </svg>
                                    Notification Center
                                </a>
                            </nav>
                        </div>
                        <hr class="h-px mt-6 w-7/8 bg-jiruto-zotSecondary border-none">
                        <div class="mt-6 flex-1 h-0 overflow-y-auto">
                            <nav class="px-2 space-y-1">
                                <router-link tag="a" to="/account" class="group flex items-center px-2 py-2 text-sm leading-6 font-medium rounded-md text-gray-200 hover:text-white hover:bg-jiruto-zotSecondary focus:outline-none focus:bg-jiruto-zotSecondary transition ease-in-out duration-100">
                                    <svg class="mr-4 h-6 w-6 text-jiruto-zotSecondary group-hover:text-jiruto-zotDarker group-focus:text-jiruto-zotDarker transition ease-in-out duration-150" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z" />
                                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                                    </svg>
                                    Account
                                </router-link>

                                <router-link tag="a" :to="{name: 'settingsMain'}" class="group flex items-center px-2 py-2 text-sm leading-6 font-medium rounded-md text-gray-200 hover:text-white hover:bg-jiruto-zotSecondary focus:outline-none focus:bg-jiruto-zotSecondary transition ease-in-out duration-100">
                                    <svg class="mr-4 h-6 w-6 text-jiruto-zotSecondary group-hover:text-jiruto-zotDarker group-focus:text-jiruto-zotDarker transition ease-in-out duration-150" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z" />
                                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                                    </svg>
                                    Settings
                                </router-link>

                                <div @click="showChat" class="group flex items-center px-2 py-2 text-sm leading-6 font-medium rounded-md text-gray-200 hover:text-white hover:bg-jiruto-zotSecondary focus:outline-none focus:bg-jiruto-zotSecondary transition ease-in-out duration-100 cursor-pointer">
                                    <svg class="mr-4 h-6 w-6 text-jiruto-zotSecondary group-hover:text-jiruto-zotDarker group-focus:text-jiruto-zotDarker transition ease-in-out duration-150" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8.228 9c.549-1.165 2.03-2 3.772-2 2.21 0 4 1.343 4 3 0 1.4-1.278 2.575-3.006 2.907-.542.104-.994.54-.994 1.093m0 3h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                    </svg>
                                    Help
                                </div>

                                <a href="https://www.iubenda.com/privacy-policy/64016478" class="iubenda-nostyle no-brand iubenda-embed group flex items-center px-2 py-2 text-sm leading-6 font-medium rounded-md text-gray-200 hover:text-white hover:bg-jiruto-zotSecondary focus:outline-none focus:bg-jiruto-zotSecondary transition ease-in-out duration-100">
                                    <svg class="mr-4 h-6 w-6 text-jiruto-zotSecondary group-hover:text-jiruto-zotDarker group-focus:text-jiruto-zotDarker transition ease-in-out duration-150" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12l2 2 4-4m5.618-4.016A11.955 11.955 0 0112 2.944a11.955 11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016z" />
                                    </svg>
                                    Privacy
                                </a>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
        </div>


    </div>
</template>

<script>
    export default {
        name: "SideMenu",
        data(){
            return{
                showMenu: false,
            }
        },
        mounted() {
            this.iubenda(window, document);
        },
        methods: {
            goToProfile(){
                const destination = `/${this.$store.getters.getUsername}`
                if(this.$router.currentRoute.path !== destination){
                    this.$router.push(destination)
                }
            },
            iubenda(w,d){
                var loader = function () {
                    var s = d.createElement("script"), tag = d.getElementsByClassName("iubenda-embed")[0];
                    s.src="https://cdn.iubenda.com/iubenda.js";
                    tag.parentNode.insertBefore(s,tag);
                };
                if(w.addEventListener){
                    w.addEventListener("load", loader, false);
                }else if(w.attachEvent){
                    w.attachEvent("onload", loader);
                }else{
                    w.onload = loader;
                }
            },
            showChat(){
                window.Intercom('show')
            }
        }
    }
</script>

<style scoped>

    .router-link-active{
        @apply bg-jiruto-zotSecondary border-none ;
    }
    .router-link-active > svg {
        @apply text-jiruto-zotDarker
    }

</style>
