<template>
    <div v-if="notification !== null">
        <component :is="notificationComponent" :notification="notification"></component>
    </div>
</template>

<script>
    import CoLearnRequestNotification from './CoLearnRequestNotification'
    import CoLearnApprovalNotification from './CoLearnApprovalNotification'
    import GroupSessionInvitationNotification from './GroupSessionInvitationNotification'
    import GroupSessionParticipationNotification from './GroupSessionParticipationNotification'
    import GroupSessionAttendeeRemovedNotification from './GroupSessionAttendeeRemovedNotification'
    import GroupSessionAttendanceNotification from './GroupSessionAttendanceNotification'
    import GroupSessionInvitationDeclineNotification from './GroupSessionInvitationDeclineNotification'
    import GroupSessionDetailsChangedNotification from './GroupSessionDetailsChangedNotification'
    import GroupSessionAttendanceCanceledNotification from './GroupSessionAttendanceCanceledNotification'
    import GroupSessionCanceledNotification from './GroupSessionCanceledNotification'
    import GroupSessionPresenterInvitationNotification from './GroupSessionPresenterInvitationNotification'
    export default {
        name: "JirutoNotification",
        props: ['notification'],
        data(){
            return {
                notificationComponent: null,
            }
        },
        components: {
            CoLearnRequestNotification,
            CoLearnApprovalNotification,
            GroupSessionInvitationNotification,
            GroupSessionParticipationNotification,
            GroupSessionAttendeeRemovedNotification,
            GroupSessionAttendanceNotification,
            GroupSessionInvitationDeclineNotification,
            GroupSessionDetailsChangedNotification,
            GroupSessionAttendanceCanceledNotification,
            GroupSessionCanceledNotification,
            GroupSessionPresenterInvitationNotification,
        },
        created(){
            switch (this.notification.type) {
                case "CoLearnRequestNotification":
                    this.notificationComponent = CoLearnRequestNotification
                    break
                case "CoLearnApprovalNotification":
                    this.notificationComponent = CoLearnApprovalNotification
                    break
                case "GroupSessionInvitationNotification":
                    this.notificationComponent = GroupSessionInvitationNotification
                    break
                case "GroupSessionParticipationNotification":
                    this.notificationComponent = GroupSessionParticipationNotification
                    break
                case "GroupSessionAttendeeRemovedNotification":
                    this.notificationComponent = GroupSessionAttendeeRemovedNotification
                    break
                case "GroupSessionAttendanceNotification":
                    this.notificationComponent = GroupSessionAttendanceNotification
                    break
                case "GroupSessionInvitationDeclineNotification":
                    this.notificationComponent = GroupSessionInvitationDeclineNotification
                    break
                case "GroupSessionDetailsChangedNotification":
                    this.notificationComponent = GroupSessionDetailsChangedNotification
                    break
                case "GroupSessionAttendanceCanceledNotification":
                    this.notificationComponent = GroupSessionAttendanceCanceledNotification
                    break
                case "GroupSessionCanceledNotification":
                    this.notificationComponent = GroupSessionCanceledNotification
                    break
                case "GroupSessionPresenterInvitationNotification":
                    this.notificationComponent = GroupSessionCanceledNotification
                    break
            }

            // if(this.notification.type === "CoLearnRequestNotification"){
            //     this.notificationComponent = CoLearnRequestNotification
            // }
            // if(this.notification.type === "CoLearnApprovalNotification"){
            //     this.notificationComponent = CoLearnApprovalNotification
            // }
            // if(this.notification.type === "GroupSessionInvitationNotification"){
            //     this.notificationComponent = GroupSessionInvitationNotification
            // }
            // if(this.notification.type === "GroupSessionParticipationNotification"){
            //     this.notificationComponent = GroupSessionParticipationNotification
            // }
        }

}
</script>

<style scoped>

</style>
