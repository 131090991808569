const hljs = require('highlight.js');
// hljs.configure()
// import hljs from 'highlight.js/lib/highlight';
import 'highlight.js/styles/github.css';
import 'highlight.js/styles/tomorrow.css';

const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
];

const shortMonths = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec'
];

const days = [
    'Sun',
    'Mon',
    'Tue',
    'Wed',
    'Thu',
    'Fri',
    'Sat'
];

export const capitalize = (s) => {
    if (typeof s !== 'string') return ''
    return s.charAt(0).toUpperCase() + s.slice(1)
}

export const httpsLink = (link) => {
    const unsafeLink = link.replace('https', 'http')
    return unsafeLink.replace('http','https')
}

export const dateFromTimestamp = (ts) => {
    let date = new Date(ts);

    const month = months[date.getMonth()];
    const day = days[date.getDay()];
    const dayOfMonth = date.getDate();
    const year = date.getFullYear();
    return `${day}, ${month} ${dayOfMonth}, ${year}`
}

export const dateFromInput = (ts) => {
    let date = new Date(ts);

    const month = months[date.getMonth()];
    const dayOfMonth = date.getDate();
    const year = date.getFullYear();
    return `${month} ${dayOfMonth}, ${year}`
}

export const timeDateFromInput = (input) => {
    let date = new Date(input);

    const month = shortMonths[date.getMonth()];
    const dayOfMonth = date.getDate();
    let year = date.getFullYear();
    if (year === 2020) {
        year = ""
    }
    const hour = date.getHours();
    const dayOfWeek = date.getDay()
    const minutes = date.getMinutes();
    let presentableMinutes = minutes > 9 ? minutes : `0${minutes}`
    return `${days[dayOfWeek]}, ${month} ${dayOfMonth}, ${year} ${hour}:${presentableMinutes}`
}


export const isSessionNow = (input) => {
    let date = new Date(input)
    const now = new Date()

    let twoHoursAfterDate = new Date(date)
    twoHoursAfterDate.setHours((date.getHours() + 2), date.getMinutes(), date.getSeconds(), date.getMilliseconds())

    return now.getTime() >= date.getTime() && now.getTime() < twoHoursAfterDate.getTime()

    // return (date.getFullYear() === now.getFullYear()
    //     && date.getMonth() === now.getMonth()
    //     && date.getDate() === now.getDate() && now.getTime() <=  date.getTime() + 7200000 )
}

export const isToday = (input) => {
    let date = new Date(input)
    const today = new Date()
    return (date.getFullYear() === today.getFullYear()
        && date.getMonth() === today.getMonth()
        && date.getDate() === today.getDate() && date.getTime() >= today.getTime())
}

export const isInNextSevenDays = (input) => {
    let date = new Date(input)
    const today = new Date()

    const sevenDays = new Date()
    sevenDays.setDate(today.getDate()+ 7)
    sevenDays.setHours(23, 59, 59, 0)

    let tomorrow = new Date()
    tomorrow.setDate(today.getDate() + 1)
    tomorrow.setHours(0, 0, 0, 0)

    return date.getTime() < sevenDays.getTime() && date.getTime() > tomorrow
}

export const isInAboveSevenDays = (input) => {
    let date = new Date(input)
    const today = new Date()
    const inYear = date.getFullYear() >= today.getFullYear()
    const inMonth = date.getMonth() >= today.getMonth()
    const inSevenDays = date.getDate() - today.getDate() > 7

    return ( inYear && inMonth &&  inSevenDays)
}

export const isInThePast = (input) => {
    let date = new Date(input)
    const today = new Date()

    return ( today.getTime() > date.getTime() + 7200000)
}

export const applyDrag = (arr, dragResult) => {
    const { removedIndex, addedIndex, payload } = dragResult
    if (removedIndex === null && addedIndex === null) return arr

    const result = [...arr]
    let itemToAdd = payload

    if (removedIndex !== null) {
        itemToAdd = result.splice(removedIndex, 1)[0]
    }

    if (addedIndex !== null) {
        result.splice(addedIndex, 0, itemToAdd)
    }

    return result
}

export const generateItems = (count, creator) => {
    const result = []
    for (let i = 0; i < count; i++) {
        result.push(creator(i))
    }
    return result
}

export const editorOption = {
    modules: {
        toolbar: [
            ['bold', 'italic', 'underline', 'strike'],
            ['blockquote', 'code-block'],
            [{ 'header': 1 }, { 'header': 2 }],
            [{ 'list': 'ordered' }, { 'list': 'bullet' }],
            [{ 'script': 'sub' }, { 'script': 'super' }],
            [{ 'indent': '-1' }, { 'indent': '+1' }],
            [{ 'direction': 'rtl' }],
            [{ 'size': ['small', false, 'large', 'huge'] }],
            [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
            [{ 'font': [] }],
            [{ 'color': [] }, { 'background': [] }],
            [{ 'align': [] }],
            ['clean'],
            ['link', 'image', 'video']
        ],
        syntax: {
            highlight: text => hljs.highlightAuto(text).value
        },
    }
}

const en = {
    moment: "1s"
    , moments: "2s"
    , seconds: "%ss"
    , minute: "1m"
    , minutes: "%mm"
    , hour: "1h"
    , hours: "%hh"
    , day: "1d"
    , days: "%Dd"
    , week: "a week ago"
    , weeks: "%w weeks ago"
    , month: "a month ago"
    , months: "%M months ago"
    , years: "more than a year ago"
    , never: "never"
};

export const timeAgo = (date) => {
    let newDate = new Date(date)
    const thisMS =  newDate.getTime()
    const now = Date.now()
    const ms = now - thisMS

    const locale = en;
    // var MOMENT = 0;
    const MOMENTS = 2;
    const SECONDS = 5;
    const MINUTE = 60;
    const HOUR = 60 * MINUTE;
    const DAY = 24 * HOUR;
    // const WEEK = 7 * DAY;
    // const MONTH = 30 * DAY;
    // const YEAR = 365 * DAY;
// workaround for when `ms = Date.now() - 0`
//     const NEVER = 45 * YEAR;

    let ago = Math.floor(ms / 1000);
    let part = 0;

    if (ago < MOMENTS) { return locale.moment; }
    if (ago < SECONDS) { return locale.moments; }
    if (ago < MINUTE) { return locale.seconds.replace(/%\w?/, ago); }

    if (ago < (2 * MINUTE)) { return locale.minute; }
    if (ago < HOUR) {
        while (ago >= MINUTE) { ago -= MINUTE; part += 1; }
        return locale.minutes.replace(/%\w?/, part);
    }

    if (ago < (2 * HOUR)) { return locale.hour; }
    if (ago < DAY) {
        while (ago >= HOUR) { ago -= HOUR; part += 1; }
        return locale.hours.replace(/%\w?/, part);
    }

    if (ago < (2 * DAY)) { return locale.day; }
    // if (ago < WEEK) {
    while (ago >= DAY) { ago -= DAY; part += 1; }
    return locale.days.replace(/%\w?/, part);
    // }

    // if (ago < (2 * WEEK)) { return locale.week; }
    // if (ago < MONTH) {
    //     while (ago >= WEEK) { ago -= WEEK; part += 1; }
    //     return locale.weeks.replace(/%\w?/, part);
    // }

    // if (ago < (2 * MONTH)) { return locale.month; }
    // if (ago < YEAR) { // 45 years, approximately the epoch
    //     while (ago >= MONTH) { ago -= MONTH; part += 1; }
    //     return locale.months.replace(/%\w?/, part);
    // }
    //
    // if (ago < NEVER) {
    //     return locale.years;
    // }

    // return locale.never;
}
