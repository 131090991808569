const firebase = require("firebase/app");
require("firebase/auth");
require("firebase/firestore");
require("firebase/analytics");
require("firebase/storage");

const firebaseConfig = {
    apiKey: "AIzaSyAAKdb53KnKQfr4r64eF9nj0rcvJCRHpbI",
    authDomain: "jiruto-1bee2.firebaseapp.com",
    databaseURL: "https://jiruto-1bee2.firebaseio.com",
    projectId: "jiruto-1bee2",
    storageBucket: "jiruto-1bee2.appspot.com",
    messagingSenderId: "834174905798",
    appId: "1:834174905798:web:38c2ddc71c27e1ab4696c7",
    measurementId: "G-7QEFXG52N1"
};

firebase.initializeApp(firebaseConfig);
firebase.analytics();

const auth = firebase.auth();
let analytics = firebase.analytics();
const currentUser = auth.currentUser;
const db = firebase.firestore();
const storage = firebase.storage();

// const token = db.doc()

const userCollection = db.collection('users')


export {
    firebase,
    auth,
    currentUser,
    db,
    userCollection,
    analytics,
    storage
    // token
}
