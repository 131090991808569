<template>
    <div class="w-full sm:fixed z-50 px-5 py-2 rounded-3xl bg-jiruto-main">
        <nav class=" rounded-3xl" style="background-color: #6F3A84">
            <div class="max-w-7xl mx-auto px-2 sm:px-4 lg:px-8">
                <div class="relative flex items-center justify-between h-16">
                    <div class="flex items-center px-2 lg:px-0">
                        <div class="flex-shrink-0">
                            <router-link to="/" tag="a">
                                <img class="block lg:hidden h-10 w-auto" src="../assets/logos/jiruto-logo.png" alt="" />
                                <img class="hidden lg:block h-10 w-auto" src="../assets/logos/jiruto-logo.png" alt="" />
                            </router-link>

                        </div>
                        <div class="hidden sm:block lg:ml-6">
                            <div class="flex items-center">
<!--                                <div v-if="!auth" class="hidden md:block text-right ml-3">-->
<!--                                    <span class="inline-flex rounded-md shadow-md">-->
<!--                                        <span class="inline-flex rounded-md shadow-xs">-->
<!--                                            <router-link to="/explore" v-slot="{ route, navigate, isActive }"-->
<!--                                                         class="inline-flex items-center px-4 py-2 border border-transparent text-base leading-6 font-medium rounded-md text-white hover:text-indigo-600 bg-jiruto-zotMain hover:bg-gray-100 focus:outline-none focus:shadow-outline-blue active:bg-gray-50 active:text-jiruto-zotSecondary transition duration-150 ease-in-out cursor-pointer">-->
<!--                                                <div :class="{'router-link-active': isActive}" @click="refreshExplore">-->
<!--                                                    Explore Jiruto-->
<!--                                                </div>-->
<!--                                            </router-link>-->
<!--                                        </span>-->
<!--                                    </span>-->
<!--                                </div>-->

<!--                                <router-link v-if="auth" exact to="/" tag="a" class="ml-4 px-3 py-2 rounded-md text-sm leading-5 font-medium text-gray-300 hover:text-white hover:bg-jiruto-zotSecondary focus:outline-none focus:text-white focus:bg-jiruto-zotSecondary transition duration-150 ease-in-out">Dashboard</router-link>-->
<!--                                <router-link to="/explore" v-if="auth" v-slot="{route, navigate, isActive }"-->
<!--                                             class="ml-4 px-3 py-2 rounded-md text-sm leading-5 font-medium text-gray-300 hover:text-white hover:bg-jiruto-zotSecondary focus:outline-none focus:text-white focus:bg-jiruto-zotSecondary transition duration-150 ease-in-out cursor-pointer">-->

<!--                                    <div :class="{'router-link-active': isActive}" @click="refreshExplore">-->
<!--                                        Explore-->
<!--                                    </div>-->
<!--                                </router-link>-->
                                <router-link to="/about-us" tag="a" class="ml-4 px-3 py-2 rounded-md text-sm leading-5 font-medium text-gray-300 hover:text-white hover:bg-jiruto-zotSecondary focus:outline-none focus:text-white focus:bg-jiruto-zotSecondary transition duration-150 ease-in-out">About Us</router-link>
<!--                                <router-link to="/about-us" tag="a" class="ml-4 px-3 py-2 rounded-md text-sm leading-5 font-medium text-gray-300 hover:text-white hover:bg-jiruto-zotSecondary focus:outline-none focus:text-white focus:bg-jiruto-zotSecondary transition duration-150 ease-in-out">New About</router-link>-->
<!--                                <router-link v-if="!auth" to="/pro-education" tag="a" class="ml-4 px-3 py-2 rounded-md text-sm leading-5 font-medium text-gray-300 hover:text-white hover:bg-jiruto-zotSecondary focus:outline-none focus:text-white focus:bg-jiruto-zotSecondary transition duration-150 ease-in-out">Jiruto Pro</router-link>-->
<!--                                <a href="https://blog.jiruto.com" target="_blank" class="ml-4 px-3 py-2 rounded-md text-sm leading-5 font-medium text-gray-300 hover:text-white hover:bg-jiruto-zotSecondary focus:outline-none focus:text-white focus:bg-jiruto-zotSecondary transition duration-150 ease-in-out">Blog</a>-->
                                <!--                            <router-link to="/" tag="a" class="ml-4 px-3 py-2 rounded-md text-sm leading-5 font-medium text-gray-300 hover:text-white hover:bg-gray-700 focus:outline-none focus:text-white focus:bg-gray-700 transition duration-150 ease-in-out">Projects</router-link>-->
                                <!--                            <router-link to="/" tag="a" class="ml-4 px-3 py-2 rounded-md text-sm leading-5 font-medium text-gray-300 hover:text-white hover:bg-gray-700 focus:outline-none focus:text-white focus:bg-gray-700 transition duration-150 ease-in-out">Calendar</router-link>-->
                            </div>
                        </div>
<!--                        <div v-if="auth && !$store.getters.isPremium" class="mr-4 hidden md:block text-right ml-3 flex-grow">-->

<!--                                    <span class="inline-flex rounded-md shadow-md">-->
<!--                                        <span class="inline-flex rounded-md shadow-xs">-->
<!--                                            <router-link to="/pro-education" tag="a" class="inline-flex items-center px-4 py-2 border border-transparent text-base leading-6 font-medium rounded-md text-white hover:text-indigo-600 bg-jiruto-zotMain hover:bg-gray-100 focus:outline-none focus:shadow-outline-blue active:bg-gray-50 active:text-jiruto-zotSecondary transition dura tion-150 ease-in-out">-->
<!--                                                Upgrade to Pro!-->
<!--                                            </router-link>-->
<!--                                        </span>-->
<!--                                    </span>-->
<!--                        </div>-->
                    </div>
<!--                    <div class="flex-1 flex justify-center content-center items-center px-2 lg:ml-6 lg:justify-end">-->
<!--                        <div v-if="showSearchInput" class="max-w-lg w-full lg:max-w-xs">-->
<!--                            <label for="search" class="sr-only">Search</label>-->
<!--                            <div class="relative">-->
<!--                                <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">-->
<!--                                    <svg class="h-5 w-5 text-gray-400" fill="currentColor" viewBox="0 0 20 20">-->
<!--                                        <path fill-rule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clip-rule="evenodd" />-->
<!--                                    </svg>-->
<!--                                </div>-->
<!--                                <input type="search" v-model="searchInput" @keypress.enter="search" id="search" class="block w-full pl-10 pr-3 py-2 border border-transparent rounded-md leading-5 bg-white placeholder-gray-400 focus:outline-none focus:bg-white sm:text-md transition duration-150 ease-in-out" placeholder="Search" />-->
<!--                            </div>-->
<!--                        </div>-->

<!--                    <div class="w-full flex justify-center">-->
<!--                        <router-link to="/communities" tag="a" class="ml-4 px-3 py-2 rounded-md text-base leading-5 font-medium text-gray-300 hover:text-white hover:bg-jiruto-zotSecondary focus:outline-none focus:text-white focus:bg-jiruto-zotSecondary transition duration-150 ease-in-out">Our Communities</router-link>-->
<!--                    </div>-->

                    <div class="flex lg:hidden">
                        <button @click="open = !open" class="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:bg-gray-700 focus:text-white transition duration-150 ease-in-out">
                            <svg class="h-6 w-6" stroke="currentColor" fill="none" viewBox="0 0 24 24">
                                <path :class="{'hidden': open, 'inline-flex': !open }" class="inline-flex" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16" />
                                <path :class="{'hidden': !open, 'inline-flex': open }" class="inline-flex" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                            </svg>
                        </button>
                    </div>
                    <div v-if="$store.state.jirutoUser.username === null" class="hidden md:block text-right ml-3">
                        <router-link to="/login" tag="a" class="m-4 px-3 py-2 rounded-md text-sm leading-5 font-medium text-gray-300 hover:text-white hover:bg-jiruto-zotSecondary focus:outline-none focus:text-white focus:bg-jiruto-zotSecondary transition duration-150 ease-in-out">Login</router-link>
                        <span class="inline-flex rounded-md shadow-md">
                        <span class="inline-flex rounded-md shadow-xs">
                            <router-link to="/signup" tag="a" active-class="active" class="inline-flex items-center px-4 py-2 border border-transparent text-base leading-6 font-medium rounded-md text-indigo-600 bg-white hover:bg-jiruto-zotDarker hover:text-white focus:outline-none focus:shadow-outline-blue active:bg-purple-600 active:text-white transition duration-150 ease-in-out">
                                 Sign Up
                            </router-link>
                        </span>
                    </span>
                    </div>
                    <div v-else>
                        <div v-if="$store.state.jirutoUser.username !== null" class="hidden lg:block lg:ml-4">
                            <div class="flex items-center">

                                <div  class="ml-4 relative flex-shrink-0">
                                    <div>
                                        <button @click="newOpen = !newOpen" class="flex text-sm rounded-full text-white focus:outline-none focus:shadow-solid transition duration-150 ease-in-out object-contain">
                                            <svg class="h-6 w-6" stroke="currentColor" fill="none" viewBox="0 0 24 24">
                                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 4v16m8-8H4" />
                                            </svg>                                        </button>
                                    </div>
                                    <transition enter-class="transform opacity-0 scale-95"
                                                enter-active-class="transition ease-out duration-100"
                                                enter-to-class="transform opacity-100 scale-100"
                                                leave-class="transform opacity-100 scale-100"
                                                leave-active-class="transition ease-in duration-75"
                                                leave-to-class="transform opacity-0 scale-95">
                                        <div v-on-clickaway="closeNewMenu" v-if="newOpen" class="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg z-30">
                                            <div class="rounded-md bg-white shadow-xs">
                                                <!--                                                <router-link to="/" tag="a" class="block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 transition duration-150 ease-in-out">Your Profile</router-link>-->
                                                <div class="block text-center px-4 py-2 text-sm leading-5 text-gray-700 bg-jiruto-perfume rounded-t-md focus:outline-none focus:bg-gray-100 transition duration-150 ease-in-out">Quick Create</div>
<!--                                                <router-link to="/newCommunity" @click.native="closeNewMenu" class="block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-purple-200 rounded-none focus:outline-none focus:bg-gray-100 transition duration-150 ease-in-out curser-pointer">New Community</router-link>-->
                                                <router-link to="/newGroupSession" @click.native="closeNewMenu" class="block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-purple-200 rounded-b-md focus:outline-none focus:bg-gray-100 transition duration-150 ease-in-out curser-pointer">New Group Session</router-link>
<!--                                                <router-link to="/account" @click.native="closeProfileMenu" class="block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 transition duration-150 ease-in-out">Account Settings</router-link>-->
<!--                                                <router-link :to="{name: 'home'}" @click.native="signOut" class="block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 rounded-b-md focus:outline-none focus:bg-gray-100 transition duration-150 ease-in-out curser-pointer">Sign out</router-link>-->
                                            </div>
                                        </div>
                                    </transition>
                                </div>

<!--                                Notifications!!!-->

<!--                                <button class="flex-shrink-0 p-1 border-2 border-transparent text-gray-400 rounded-full hover:text-white focus:outline-none focus:text-white focus:bg-gray-700 transition duration-150 ease-in-out">-->
<!--                                    <svg class="h-6 w-6" stroke="currentColor" fill="none" viewBox="0 0 24 24">-->
<!--                                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9" />-->
<!--                                    </svg>-->
<!--                                </button>-->

                                <div v-if="$store.state.jirutoUser.username !== null"  class="ml-4 relative flex-shrink-0">
                                    <div>
                                        <button @click="profileOpen = !profileOpen" class="flex text-sm rounded-full text-white focus:outline-none focus:shadow-solid transition duration-150 ease-in-out object-contain">
                                            <img class="h-8 w-8 rounded-full object-contain bg-gray-400" :src="getUser.avatar_url" alt="" />
                                        </button>
                                    </div>
                                    <transition enter-class="transform opacity-0 scale-95"
                                                enter-active-class="transition ease-out duration-100"
                                                enter-to-class="transform opacity-100 scale-100"
                                                leave-class="transform opacity-100 scale-100"
                                                leave-active-class="transition ease-in duration-75"
                                                leave-to-class="transform opacity-0 scale-95">
                                        <div v-on-clickaway="closeProfileMenu" v-if="profileOpen" class="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg z-30">
                                            <div class="rounded-md bg-white shadow-xs">
<!--                                                <router-link to="/" tag="a" class="block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 transition duration-150 ease-in-out">Your Profile</router-link>-->
                                                <div class="block text-center px-4 py-2 text-sm leading-5 text-gray-700 bg-gray-300 rounded-t-md focus:outline-none focus:bg-gray-200 transition hover:text-gray-700 duration-150 ease-in-out">Welcome {{ getUser.username }}</div>
                                                <router-link exact :to="{name: 'profile', params: {username: getUser.username}}" @click.native="closeProfileMenu" class="block px-4 py-2 text-sm leading-5 hover:text-gray-700 text-gray-700 hover:bg-gray-200 rounded-none focus:outline-none focus:bg-gray-100 transition duration-150 ease-in-out curser-pointer">My Profile</router-link>
                                                <router-link to="/account" @click.native="closeProfileMenu" class="block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-200 hover:text-gray-700 focus:outline-none focus:bg-gray-100 transition duration-150 ease-in-out">Account Settings</router-link>
                                                <div :to="{name: 'home'}" @click="signOut" class="block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-200 hover:text-gray-700 rounded-b-md focus:outline-none focus:bg-gray-100 transition duration-150 ease-in-out cursor-pointer">Sign out</div>
                                            </div>
                                        </div>
                                    </transition>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div :class="{'block': open, 'hidden': !open}" class="lg:hidden">
                <div v-if="open" class="rounded-lg shadow-md transition transform origin-top-right">

                </div>
                <div class="px-2 pt-2 pb-3">
                    <router-link v-if="auth" exact to="/" tag="a" class="block px-3 py-2 rounded-md text-base font-medium text-gray-300 hover:text-white hover:bg-jiruto-zotSecondary focus:outline-none focus:text-white focus:bg-jiruto-zotSecondary transition duration-150 ease-in-out">Dashboard</router-link>
<!--                    <router-link to="/explore" tag="a" class="mt-1 block px-3 py-2 rounded-md text-base font-medium text-gray-300 hover:text-white hover:bg-jiruto-zotSecondary focus:outline-none focus:text-white focus:bg-jiruto-zotSecondary transition duration-150 ease-in-out">Explore</router-link>-->
                    <router-link to="/about-us" tag="a" class="mt-1 block px-3 py-2 rounded-md text-base font-medium text-gray-300 hover:text-white hover:bg-jiruto-zotSecondary focus:outline-none focus:text-white focus:bg-jiruto-zotSecondary transition duration-150 ease-in-out">About Us</router-link>
<!--                    <router-link to="/about-us" tag="a" class="mt-1 block px-3 py-2 rounded-md text-base font-medium text-gray-300 hover:text-white hover:bg-jiruto-zotSecondary focus:outline-none focus:text-white focus:bg-jiruto-zotSecondary transition duration-150 ease-in-out">New About</router-link>-->
<!--                    <router-link to="/pro-education" tag="a" class="mt-1 block px-3 py-2 rounded-md text-base font-medium text-gray-300 hover:text-white hover:bg-jiruto-zotSecondary focus:outline-none focus:text-white focus:bg-jiruto-zotSecondary transition duration-150 ease-in-out">Jiruto Pro</router-link>-->
<!--                    <a href="https://blog.jiruto.com" target="_blank" class="mt-1 block px-3 py-2 rounded-md text-base font-medium text-gray-300 hover:text-white hover:bg-jiruto-zotSecondary focus:outline-none focus:text-white focus:bg-jiruto-zotSecondary transition duration-150 ease-in-out">Blog</a>-->
<!--                    <router-link to="/" tag="a" class="mt-1 block px-3 py-2 rounded-md text-base font-medium text-gray-300 hover:text-white hover:bg-gray-700 focus:outline-none focus:text-white focus:bg-gray-700 transition duration-150 ease-in-out">Projects</router-link>-->
<!--                    <router-link to="/" tag="a" class="mt-1 block px-3 py-2 rounded-md text-base font-medium text-gray-300 hover:text-white hover:bg-gray-700 focus:outline-none focus:text-white focus:bg-gray-700 transition duration-150 ease-in-out">Calendar</router-link>-->
                </div>
                <div class="mx-3 pt-4 pb-3 border-t border-gray-700">
                    <router-link to="/login" tag="a" class="mt-1 mb-3 block px-3 py-2 rounded-md text-base font-medium text-gray-300 hover:text-white hover:bg-jiruto-zotSecondary focus:outline-none focus:text-white focus:bg-jiruto-zotSecondary transition duration-150 ease-in-out">Login</router-link>
                    <div v-if="!auth">
                        <router-link to="/signup" tag="a" class="block ml-2 mr-2 rounded-md px-5 py-3 text-center font-medium text-indigo-600 bg-gray-50 hover:bg-jiruto-zotDarker hover:text-indigo-700 focus:outline-none focus:ring-blue-500 focus:text-indigo-700 transition duration-150 ease-in-out">
                            Sign Up
                        </router-link>
                    </div>
                    <div v-else>
                        <div class="flex items-center px-5">
                            <div class="flex-shrink-0">
                                <img class="h-10 w-10 rounded-full" :src="getUser.avatar_url" alt="" />
                            </div>
                            <div class="ml-3">
                                <div class="text-base font-medium leading-6 text-white">{{getUser.username}}</div>
                                <div class="text-sm font-medium leading-5 text-gray-400">{{getUser.email}}</div>
                            </div>
                        </div>
                        <div class="mt-3 px-2">
                            <router-link :to="`/${getUser.username}`" tag="a" class="block px-3 py-2 rounded-md text-base font-medium text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:text-white focus:bg-gray-700 transition duration-150 ease-in-out">Your Profile</router-link>
                            <router-link to="/settings" tag="a" class="mt-1 block px-3 py-2 rounded-md text-base font-medium text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:text-white focus:bg-gray-700 transition duration-150 ease-in-out">Settings</router-link>
                            <router-link to="/login" tag="a" class="mt-1 block px-3 py-2 rounded-md text-base font-medium text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:text-white focus:bg-gray-700 transition duration-150 ease-in-out">Sign out</router-link>
                        </div>
                    </div>
                </div>
            </div>
        </nav>
    </div>

</template>

<script>
    import {EventBus} from '@/jiruto-bus'
    import {mapGetters} from 'vuex'
    import {auth} from '@/firebaseConfig'
    import {directive as onClickaway} from 'vue-clickaway'
    import * as fb from '@/firebaseConfig'
    export default {
        name: "MainNavBar",
        directives: {
            onClickaway
        },
        data() {
            return {
                open: false,
                profileOpen: false,
                newOpen: false,
                searchInput: "",
            }
        },

        computed: {
            ...mapGetters(['getUser','getAvatarUrl','getUsername']),
            auth() {
                // return this.$store.getters.isAuthenticated
                return fb.auth.currentUser !== null
            },
            currentUser(){
                return auth.currentUser
            },
            showSearchInput(){
                return this.$route.name !== "explore"
            },
        },
        methods: {
            refreshExplore(){
                if(this.$router.currentRoute.name === "explore"){
                    EventBus.$emit('refreshExplore');
                } else {
                    this.$router.push('/explore')
                }
            },
            signOut() {
                this.$store.dispatch('signOut')
                    .then(() => {
                        this.$router.push('/')
                        this.closeProfileMenu()
                    })
                    .catch(err => console.error("Error signing out", err))
            },
            closeProfileMenu(){
                this.profileOpen = false
            },
            closeNewMenu(){
                this.newOpen = false
            },
            closeMenus(){
                this.closeNewMenu()
                this.closeProfileMenu()
            },
            search(){
                if (this.$route.path === '/explore'){
                    console.log(this.searchInput)
                    // this.$store.dispatch('setSearchTerm', this.searchInput)
                    this.$emit("search", this.searchInput)
                } else {
                    // this.$router.push({name: 'explore', params: {queryProp: this.searchInput}, query: {q: this.searchInput}})
                    this.$router.push({name: 'explore', query: {q: this.searchInput}})
                }
            }
        },
    }
</script>

<style scoped>
    .router-link-active{
        @apply bg-jiruto-zotSecondary text-white
    }
    .active{
        @apply bg-jiruto-zotSecondary text-white
    }

</style>
