<template>
    <div v-if="show" class="h-full fixed right-0 flex overflow-hidden z-50">
        <!-- Off-canvas menu for mobile -->
        <div class="lg:hidden">
            <div class="fixed inset-0 flex z-40">
                <!--
                  Off-canvas menu overlay, show/hide based on off-canvas menu state.

                  Entering: "transition-opacity ease-linear duration-300"
                    From: "opacity-0"
                    To: "opacity-100"
                  Leaving: "transition-opacity ease-linear duration-300"
                    From: "opacity-100"
                    To: "opacity-0"
                -->

                <transition enter-class="opacity-0"
                            enter-active-class="transition-opacity ease-linear duration-300"
                            enter-to-class="opacity-100"
                            leave-class="opacity-100"
                            leave-active-class="transition-opacity ease-linear duration-300"
                            leave-to-class="opacity-0">

                    <div v-if="showMenu" class="fixed inset-0">
                        <div class="absolute inset-0 bg-cool-gray-600 opacity-75"></div>
                    </div>

                </transition>
                <!--
                  Off-canvas menu, show/hide based on off-canvas menu state.

                  Entering: "transition ease-in-out duration-300 transform"
                    From: "-translate-x-full"
                    To: "translate-x-0"
                  Leaving: "transition ease-in-out duration-300 transform"
                    From: "translate-x-0"
                    To: "-translate-x-full"
                -->

                <transition enter-class="-translate-x-full"
                            enter-active-class="transition ease-in-out duration-300 transform"
                            enter-to-class="translate-x-0"
                            leave-class="translate-x-0"
                            leave-active-class="transition ease-in-out duration-300 transform"
                            leave-to-class="-translate-x-full">

                    <div v-if="showMenu" class="relative flex-1 flex flex-col max-w-xs w-full pt-5 pb-4 bg-jiruto-menu">
                        <div class="absolute top-0 right-0 -mr-14 p-1">
                            <button class="flex items-center justify-center h-12 w-12 rounded-full focus:outline-none focus:bg-cool-gray-600" aria-label="Close sidebar">
                                <svg class="h-6 w-6 text-white" stroke="currentColor" fill="none" viewBox="0 0 24 24">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                                </svg>
                            </button>
                        </div>
                        <div class="flex-shrink-0 flex items-center px-4">
                            <!--                        <img class="h-8 w-auto" src="https://tailwindui.com/img/logos/easywire-logo-on-brand.svg" alt="Easywire logo">-->
                            <p class="text-xl font-medium"> Notifications </p>
                        </div>
                        <div class="mt-5 overflow-y-auto">

                        </div>
                        <hr class="h-px mt-6 bg-teal-700 border-none">
                        <div class="mt-6 flex-1 h-0 overflow-y-auto">
                            <nav class="px-2 space-y-1">
                                <a href="#" class="group flex items-center px-2 py-2 text-base leading-6 font-medium rounded-md text-teal-100 hover:text-white hover:bg-teal-500 focus:outline-none focus:bg-teal-500 transition ease-in-out duration-150">
                                    <svg class="mr-4 h-6 w-6 text-teal-200 group-hover:text-teal-200 group-focus:text-teal-200 transition ease-in-out duration-150" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z" />
                                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                                    </svg>
                                    Settings
                                </a>

                                <a href="#" class="group flex items-center px-2 py-2 text-base leading-6 font-medium rounded-md text-teal-100 hover:text-white hover:bg-teal-500 focus:outline-none focus:bg-teal-500 transition ease-in-out duration-150">
                                    <svg class="mr-4 h-6 w-6 text-teal-300 group-hover:text-teal-200 group-focus:text-teal-200 transition ease-in-out duration-150" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8.228 9c.549-1.165 2.03-2 3.772-2 2.21 0 4 1.343 4 3 0 1.4-1.278 2.575-3.006 2.907-.542.104-.994.54-.994 1.093m0 3h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                    </svg>
                                    Help
                                </a>

                                <a href="#" class="group flex items-center px-2 py-2 text-base leading-6 font-medium rounded-md text-teal-100 hover:text-white hover:bg-teal-500 focus:outline-none focus:bg-teal-500 transition ease-in-out duration-150">
                                    <svg class="mr-4 h-6 w-6 text-teal-300 group-hover:text-teal-200 group-focus:text-teal-200 transition ease-in-out duration-150" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12l2 2 4-4m5.618-4.016A11.955 11.955 0 0112 2.944a11.955 11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016z" />
                                    </svg>
                                    Privacy
                                </a>

                                <a href="#" class="group flex items-center px-2 py-2 text-base leading-6 font-medium rounded-md text-teal-100 hover:text-white hover:bg-teal-500 focus:outline-none focus:bg-teal-500 transition ease-in-out duration-150">
                                    <svg class="mr-4 h-6 w-6 text-teal-300 group-hover:text-teal-200 group-focus:text-teal-200 transition ease-in-out duration-150" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12l2 2 4-4m5.618-4.016A11.955 11.955 0 0112 2.944a11.955 11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016z" />
                                    </svg>
                                    Privacy
                                </a>
                            </nav>
                        </div>
                    </div>

                </transition>

                <div class="flex-shrink-0 w-14">
                    <!-- Dummy element to force sidebar to shrink to fit close icon -->
                </div>
            </div>
        </div>

        <!-- Static sidebar for desktop -->
        <div class="hidden lg:flex lg:flex-shrink-0">
            <div class="flex flex-col w-64">
                <!-- Sidebar component, swap this element with another sidebar if you like -->
                <div class="flex flex-col flex-grow pt-5 pb-4 overflow-y-auto">
                    <div class="flex items-center justify-center flex-shrink-0 px-4 w-full">
<!--                        <img class="h-8 w-auto" src="https://tailwindui.com/img/logos/easywire-logo-on-brand.svg" alt="Easywire logo">-->
                        <div class="pb-5 border-b w-full text-center border-gray-200 space-y-3 sm:flex sm:items-center sm:justify-center sm:space-x-4 sm:space-y-0">
                            <h3 class="text-2xl leading-6 font-medium text-white">
                                Notifications
                            </h3>
<!--                            <div>-->
<!--                                <span class="shadow-sm rounded-md">-->
<!--                                    <button type="button" class="inline-flex items-center px-3 py-1 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-red-500 hover:bg-red-600 focus:outline-none focus:shadow-outline-indigo focus:border-indigo-700 active:bg-indigo-700 transition duration-150 ease-in-out">-->
<!--                                        Clear-->
<!--                                    </button>-->
<!--                                </span>-->
<!--                            </div>-->
                        </div>
                    </div>
                    <div class="my-5 flex-1 flex flex-col overflow-y-auto">
                        <div class="overflow-y-auto">

                        </div>
                        <div class="mt-5 mb-10 flex-1 h-0 overflow-y-auto">
                            <transition-group name="notifications" tag="div" class="px-2 space-y-1"
                                              enter-class="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
                                              enter-active-class="transform ease-out duration-500 transition"
                                              enter-to-class="translate-y-0 opacity-100 sm:translate-x-0"
                                              leave-class="transform opacity-100 scale-y-100"
                                              leave-active-class="transition ease-in duration-100"
                                              leave-to-class="transform opacity-0 scale-y-95"
                            >
                                <div v-for="notification in notificationsCreatedDesc" :key="notification.JID">
                                    <jiruto-notification :notification="notification"></jiruto-notification>
                                </div>
                            </transition-group>
<!--                            <div class="px-2 space-y-1">-->

<!--                            </div>-->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import * as _ from 'lodash'
    import * as fb from '../firebaseConfig'
    import JirutoNotification from "@/components/notifications/JirutoNotification";
    export default {
        name: "NotificationMenu",
        components: {JirutoNotification},
        data(){
            return {
                notifications: [],
                showMenu: false,
            }
        },
        created(){
            const uid = fb.auth.currentUser.uid
            fb.db.collection("notifications").doc(uid).collection("notifications").where("read", "==", false)
            .onSnapshot((querySnapshot) => {
                let notifications = [];
                querySnapshot.forEach(function(doc) {
                    notifications.push(doc.data());
                    // console.log("notification:", doc.data())
                });
                this.notifications = notifications
                // console.log(notifications)
            });
        },
        computed: {
            show(){
                return true
            },
            notificationsCreatedDesc(){
                return _.orderBy(this.notifications, ['createdAt'], ['desc'])
            }
        },
        methods: {
            printNotifications(){
                console.log("Notifications are", this.notifications)
            }
        },

    }
</script>

<style scoped>

</style>
