import * as _ from 'lodash'
import * as axios from '@/axios-auth'

export default {
    state: {
        communityName: null,
        communityOwner: null,
        discussions: {
            posts: [],
            currentPost: {
                title: null,
                content: null,
                lastUpdated: null,
                createdAt: null,
                creator: null,
                comments: null,
                commentsCount: null,
            },
            savedCommentsIDs: [],
        },
        // integrations: {
        //     board: null,
        //     forum: null,
        //     groupSessions: null,
        // },
        integrations: null,
        boards: [],
        communityDetails: {
            default: null,
            description: null,
            avatar_url: null,
            full_name: null,
            name: null,
            private: null,
            lastUpdated: null,
            starsCount: null,
            subsCount: null,
        },
        isSubscribed: null,
        subsCount: 0,
    },

    getters: {

    },

    mutations: {
        setBoards(state, boards){
            state.boards = boards
        },
        addBoard(state, board){
            state.boards.push(board)
        },
        setIsSubscribed(state, value){
            state.isSubscribed = value
        },
        setCommunityDetails(state, value){
            state.communityDetails = value
        },
        setSubsCount(state, value){
            state.communityDetails.subsCount = value
        },
        setCommunityOwner(state, value){
            state.communityOwner = value
        },
        setCommunityName(state, value){
            state.communityName = value
        },
        exitCommunity(state) {
            state.integrations = null
            state.boards = null
            state.discussions = {
                posts: [],
                currentPost: {
                    title: null,
                    content: null,
                    lastUpdated: null,
                    createdAt: null,
                    creator: null,
                    comments: null,
                    commentsCount: null,
                },
                savedCommentsIDs: [],
            }
            state.isSubscribed = null
            state.subsCount = 0
            // state.integrations.board = "unintegrated"
            // state.integrations.groupSessions = "unintegrated"
            // state.integrations.forum = "unintegrated"
            state.communityDetails = {
                default: null,
                description: null,
                avatar_url: null,
                full_name: null,
                name: null,
                private: null,
                lastUpdated: null,
                starsCount: 0,
                subsCount: 0,
            }
        },
        setPost(state, post){
            state.discussions.currentPost = post
        },
        setIntegrationStatus(state, integration){
            state.integrations[integration.name] = integration.status
        },
        setIntegrations(state, integrations){
            state.integrations = integrations
        },
        // setBoardIntegrationStatus(state, status){
        //     state.integrations.board = status
        // },
        // setForumIntegrationStatus(state, status){
        //     state.integrations.forum = status
        // },
        // setGroupSessionsIntegrationStatus(state, status){
        //     state.integrations.groupSessions = status
        // },
        activateIntegration(state, integrationName){
            const index =  _.findIndex(state.integrations, (integration) => {
                return integration.name === integrationName
            });
            state.integrations[index].status = "active"
        },
        addIntegration(state, newIntegration){
            const index =  _.findIndex(state.integrations, (integration) => {
                return integration.name === newIntegration.name
            });
            if (index !== -1) {
                return
            }
            state.integrations.push(newIntegration)
            console.log("Integracionaise:", state.integrations)
        },
        disableIntegration(state, integrationName){
            const index =  _.findIndex(state.integrations, (integration) => {
                return integration.name === integrationName
            });
            state.integrations[index].status = "disabled"
        },
        setSavedCommentsIDs(state, savedCommentsIDs){
            state.discussions.savedCommentsIDs = savedCommentsIDs
        },
        addSavedCommentID(state, commentID){
            state.discussions.savedCommentsIDs.splice(0, 0, commentID)
        },
        removeSavedCommentID(state, commentID){
            const index =  _.findIndex(state.discussions.savedCommentsIDs, (id) => {
                return id === commentID
            });
            state.discussions.savedCommentsIDs.splice(index, 1)
        },
    },

    actions: {
        getIntegrationStatus(context, integrationName){
            if (context.state.integrations === null) {
                return null
                // return resolve(null)
            }
            for (let integration of context.state.integrations) {
                if (integrationName === integration.name) {
                    console.log("YES!")
                    return integration.status
                    // return resolve(integration.status)
                }
            }
            // return new Promise((resolve) => {
            //
            // })
        },
        setBoards(context, boards){
            context.commit("setBoards", boards)
        },
        addBoard(context, board){
            context.commit("addBoard", board)
        },
        setCommunityOwner(context, value){
            context.commit("setCommunityOwner", value)
        },
        setCommunityName(context, value){
            context.commit("setCommunityName", value)
        },
        exitCommunity(context){
            context.commit("exitCommunity")
        },
        activateIntegration(context, integration) {
            context.commit("activateIntegration", integration)
        },
        addIntegration(context, integration){
            context.commit("addIntegration", integration)
        },
        disableIntegration(context, integration) {
            context.commit("disableIntegration", integration)
        },
        // setIntegrations(context, integrations){
        //     for (let integration of integrations) {
        //         switch (integration.name){
        //             case "Boards":
        //                 context.commit("setBoardIntegrationStatus", integration)
        //                 break;
        //             case "Forum":
        //                 context.commit("setForumIntegrationStatus", integration)
        //                 break;
        //             case "Group Sessions":
        //                 context.commit("setGroupSessionsIntegrationStatus", integration)
        //                 break;
        //         }
        //     }
        // },
        setIntegrations(context, integrations){
            context.commit("setIntegrations", integrations)
        },
        setPost(context, post){
            context.commit('setPost', post)
        },
        getIsSubscribed(context){
            return new Promise((resolve, reject) => {
                axios.jigo.get(`/v2/user/subscription/${context.state.communityOwner}/${context.state.communityName}`, {
                    validateStatus: (status) => {
                        return status < 500
                    }
                })
                .then((resp) => {
                    if (resp.status === 200) {
                        // this.isSubscribed = resp.data
                        context.commit("setIsSubscribed", resp.data)
                        resolve()
                    } else {
                        // this.isSubscribed = false
                        context.commit("setIsSubscribed", false)
                        reject()
                    }
                })
                .catch((err) => {
                    console.error("An error occurred while trying to find if the user is subscribed: ", err)
                    context.commit("setIsSubscribed", false)
                    reject(err)
                })
            })
        },
        subscribe(context){
            return new Promise((resolve, reject) => {

                context.commit("setIsSubscribed", true)
                context.commit("setSubsCount", context.state.communityDetails.subsCount + 1)
                 axios.jigo.put(`/v2/user/subscription/${context.state.communityOwner}/${context.state.communityName}`, {validateStatus: (status) => {
                        return status < 500
                    }})
                .then((resp) => {
                    if (resp.status !== 201) {
                        console.log("not working")
                        context.commit("setIsSubscribed", false)
                        context.commit("setSubsCount", context.state.communityDetails.subsCount - 1)
                        reject()
                    }
                    console.log("Yeahhhhhh")
                    resolve()
                })
                .catch((err) => {
                    context.commit("setIsSubscribed", false)
                    context.commit("setSubsCount", context.state.communityDetails.subsCount - 1)
                    console.error("ERROR: subscribe FAILED", err)
                    reject(err)
                })
            })
        },
        unSubscribe(context){
            context.commit("setIsSubscribed", false)
            context.commit("setSubsCount", context.state.communityDetails.subsCount - 1)
            axios.jigo.delete(`/v2/user/subscription/${context.state.communityOwner}/${context.state.communityName}`, {validateStatus: (status) => {
                    return status < 500
                }})
            .then((resp) => {
                if (resp.status !== 204) {
                    context.commit("setIsSubscribed", true)
                    context.commit("setSubsCount", context.state.communityDetails.subsCount + 1)
                }
            })
            .catch((err) => {
                context.commit("setIsSubscribed", true)
                context.commit("setSubsCount", context.state.communityDetails.subsCount + 1)
                console.error("An error occurred while trying to unsubscribe the user: ", err)
            })
        },
        fetchSavedCommentsIDs(){

        },
        addSavedCommentID(context, commentID){
            context.commit('addSavedCommentID', commentID)
        },
        removeSavedCommentID(context, commentID){
            context.commit('removeSavedCommentID', commentID)
        },
        setSavedCommentsIDs(context, savedCommentsIDs){
            context.commit('setSavedCommentsIDs', savedCommentsIDs)
        }
    },
}
