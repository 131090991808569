import Vue from 'vue'
import VueRouter from 'vue-router'
import Meta from 'vue-meta'
import myPages from "@/router/myPages";
import settings from "@/router/settings"
import community from "@/router/community"



import * as fb from '../firebaseConfig'

Vue.use(VueRouter)
Vue.use(Meta);


const routes = [
  {
    path: '',
    name: 'home',

    component: () => import('../views/Home.vue'),
    beforeEnter: (to, from, next) => {
      if(fb.auth.currentUser && !fb.auth.currentUser.emailVerified){
        next("/pleaseVerify")
      }
      if(fb.auth.currentUser && fb.auth.currentUser.emailVerified){
        next("/my/dashboard")
      }
      next()
    }
  },
  {
    path: '/groupSession/:sessionID',
    name: 'groupSessionPage',
    props: route => ({ anonymousUser: {JID: route.query.JID, magicToken: route.query.magicToken}, sessionID: route.params.sessionID}),
    // props: true,
    component: () => import('../views/GroupSessionPage.vue')
  },
  {
    path: '/newLandingPage',
    name: 'newLandingPage',
    // props: route => ({ anonymousUser: {JID: route.query.JID, magicToken: route.query.magicToken}, sessionID: route.params.sessionID}),
    // props: true,
    component: () => import('@/pages/landing/NewLandingPage.vue')
  },
  {
    path: '/lp2',
    name: 'lp2',
    // props: route => ({ anonymousUser: {JID: route.query.JID, magicToken: route.query.magicToken}, sessionID: route.params.sessionID}),
    // props: true,
    component: () => import('@/pages/landing/LP2.vue')
  },
    myPages,
  {
    path: '/admin',
    // name: 'admin',
    component: () => import('../pages/admin/Admin.vue'),
    children: [
      {
        path: '',
        name: 'dummyAdmin',
        redirect: {name: 'adminUsers'}
      },
      {
        path: 'users',
        name: 'adminUsers',
        component: () => import('../pages/admin/AdminUsers.vue')
      },
      {
        path: 'dashboard',
        name: 'adminDashboard',
        component: () => import('../pages/admin/AdminDashboard.vue')
      },
    ]
  },

  settings,
  {
    path: '/account',
    // name: 'account',
    component: () => import('../views/Account.vue'),
    children: [
      {
        path: '',
        name: 'dummyAccount',
        redirect: {name: 'accountSettings'}
      },
      {
        path: 'settings',
        name: 'accountSettings',
        component: () => import('../views/ChangePassword')
      },
      {
        path: 'subscription',
        name: 'accountSubscription',
        component: () => import('../views/AccountSubscription.vue')
      },
    ],
    meta: {
      requiresVerified: true,
    },
  },
  {
    path: '/404',
    name: '404',
    component: () => import('../views/PageNotFound.vue')
  },
  {
    path: '/explore',
    name: 'explore',
    props: true,
    component: () => import('../views/Explore.vue')
  },
  {
    path: '/about-us',
    name: 'aboutUs',
    component: () => import('../views/NewAboutUs.vue')
  },
  {
    path: '/about',
    name: 'about',
    component: () => import('../views/About.vue')
  },
  {
    path: '/privacy',
    name: 'privacy',
    component: () => import('../views/Privacy.vue')
  },

  {
    path: '/proWelcome',
    name: 'proWelcome',
    component: () => import('../views/ProWelcome.vue')
  },
  // {
  //   path: '/pro-education',
  //   name: 'proEducation',
  //   component: () => import('../views/ProEducation.vue')
  // },
  {
    path: '/terms',
    name: 'terms',
    component: () => import('../views/About.vue')
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/Login.vue'),
    meta: {
      requiresUnAuth: true,
    },
  },
  {
    path: '/signup',
    name: 'signup',
    component: () => import('../views/SignUp.vue'),
    meta: {
      requiresUnAuth: true
    },
  },
  {
    path: '/pleaseVerify',
    name: 'verify',
    component: () => import(/* webpackChunkName: "about" */ '../views/Verify.vue'),
    meta: {
      requiresUnVerified: true,
    },
  },
  {
    path: '/newCommunity',
    name: 'newCommunity',
    component: () => import(/* webpackChunkName: "about" */ '../views/NewCommunity.vue'),
    meta: {
      requiresVerified: true,
    },
  },
  {
    path: '/newGroupSession',
    name: 'newGroupSession',
    component: () => import(/* webpackChunkName: "about" */ '../views/NewGroupSession.vue'),
    props: route => ({ communityOwner: route.query.communityOwner, communityName: route.query.communityName}),

    meta: {
      requiresVerified: true,
    },
  },
  {
    path: '/account/upgrade',
    name: 'upgrade',
    component: () => import(/* webpackChunkName: "about" */ '../views/Upgrade.vue'),
    meta: {
      requiresVerified: true,
    },
  },
  community,
  {
    path: '/:username',
    name: 'profile',
    component: () => import('../pages/profile/Profile.vue'),
    props: true,
    meta: {
      requiresVerified: true,
    },
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

router.beforeEach(async (to, from, next) => {
  const requiresVerified = to.matched.some(record => record.meta.requiresVerified);
  const requiresUnVerified = to.matched.some(record => record.meta.requiresUnVerified);
  const requiresUnAuth = to.matched.some(record => record.meta.requiresUnAuth);

  // console.log("To and From: ", to, from)

  let authed = !!fb.auth.currentUser
  // console.log("Authed is: ", authed)

  let authButNoVerify = false
  if(authed){
    if(!fb.auth.currentUser.emailVerified){
      authButNoVerify = true
    }
  }

  // console.log("authButNoVerify is: ", authButNoVerify)
  if (authed){
    // console.log("email veirifcation: ", fb.auth.currentUser.emailVerified)
  }


  if(requiresVerified) {
    if(!authed){
      next('/login')
    } else if(authButNoVerify){
      next('/pleaseVerify')
    } else {
      next()
    }
  }

  if (requiresUnVerified){
    if(authButNoVerify){
      next()
    } else if (!authed){
      next('/login')
    } else {
      next('/')
    }
  }

  if(requiresUnAuth){
    if(!authed){
      next()
    } else{
      if(authButNoVerify){
        next('/pleaseVerify')
      }else {
        next('/')
      }
    }
  }

  next()


});

export default router
