import store from './store'
import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import VueSocialSharing from 'vue-social-sharing'
import hljs from 'highlight.js';
import 'highlight.js/styles/github.css';
import VCalendar from 'v-calendar';

import { library } from '@fortawesome/fontawesome-svg-core'
import { faUserSecret } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'


library.add(faUserSecret)

Vue.component('font-awesome-icon', FontAwesomeIcon)


// import {
//     load as loadIntercom,
//     boot as bootIntercom,
//     update as updateIntercom
// } from "./intercom"


import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import {directive as onClickaway} from 'vue-clickaway';
import '@/assets/styles/tailwind.css';
import Vuelidate from 'vuelidate';
import * as fb from './firebaseConfig';
let mixpanel = require('mixpanel-browser');
// import './matomoConfig'

mixpanel.init("f4d3135af667155a2c52a334b436260d");

// Vue.use(VueMeta);
Vue.use(VueSocialSharing);
Vue.config.productionTip = false;
Vue.use(Vuelidate);

Vue.use(Loading, {
  canCancel: true,
  loader: "dots",
  color: "#45b3c2",
  width: 128,
  height: 128,
});

Vue.use(VCalendar, {
    componentPrefix: 'vc', // Now use vc-calendar and vc-date-picker
    datePicker: {
        popover: {
            visibility: 'focus'
        }
    }
})

Vue.directive(onClickaway);
// Vue.prototype.$analytics = fb.analytics;
Vue.prototype.$mixpanel = mixpanel

hljs.initHighlightingOnLoad();
Vue.use(hljs.vuePlugin);

let app
fb.auth.onAuthStateChanged(() => {
    if (!app){
        store.dispatch('getUserData').finally(() => {
           app =  new Vue({
                router,
                store,
                render: h => h(App)
            }).$mount('#app')
        })
            .catch(err => {
                console.error("Couldn't fetch user data", err)
            })
    }
});

