<template>
    <router-link tag="div" :to="`/${notification.attendee.username}`" v-if="notification !== null">
        <div class="max-w-sm w-full bg-white shadow-lg rounded-lg pointer-events-auto cursor-pointer">
            <div class="rounded-lg shadow-xs">
                <div class="p-4 relative">
                    <div class=" flex items-center">
                        <div class="flex-shrink-0 pt-0.5">
                            <img class="h-8 w-8 rounded-full" :src="notification.attendee.avatarUrl" alt="">
                        </div>
                        <div class="ml-3 w-0 flex-1">
                            <p class="text-sm leading-5 font-medium text-gray-900">
                                {{userFullName}}
                            </p>
                        </div>
                        <div class="absolute top-2 right-2 ml-4 flex-shrink-0 flex">
                            <button @click.stop="markRead" class="inline-flex text-gray-400 focus:outline-none focus:text-gray-500 transition ease-in-out duration-150">
                                <!-- Heroicon name: x -->
                                <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                    <path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd" />
                                </svg>
                            </button>
                        </div>
                    </div>
                    <p class="mt-2 ml-1 text-sm leading-5 text-gray-500">
                        <span class="font-bold text-jiruto-flatRed">Canceled</span>
                        their attendance to session:
                        <span class="font-medium text-indigo-500 hover:text-indigo-400 active:text-indigo-600 ">
                             <router-link tag="a" :to="`/groupSession/${notification.groupSession.JID}`">
                                 {{ notification.groupSession.topic }}
                             </router-link>
                        </span>
                    </p>
                </div>
            </div>
        </div>
    </router-link>
</template>

<script>
    import * as fb from '@/firebaseConfig'
    export default {
        name: "GroupSessionAttendanceCanceledNotification",
        props:['notification'],
        computed: {
            userFullName(){
                return `${this.notification.attendee.firstName} ${this.notification.attendee.lastName}`
            }
        },
        methods: {
            markRead(){
                fb.db.collection("notifications").doc(fb.auth.currentUser.uid)
                .collection("notifications").doc(this.notification.JID).update({
                    read: true
                })
            },
        }

}
</script>

<style scoped>

</style>
