export default {
    path: '/settings',
    // name: 'settings',
    component: () => import('../views/MySettings'),
    children: [
        {
            path: '',
            name: 'settingsMain',
            redirect: {name: 'settingsProfile'}
        },
        {
            path: 'password',
            name: 'settingsPassword',
            component: () => import('../views/ChangePassword')
        },
        {
            path: 'profile',
            name: 'settingsProfile',
            component: () => import('@/components/SettingsProfile.vue')
        },
        {
            path: 'general',
            name: 'settingsGeneral',
            component: () => import('@/components/SettingsGeneral.vue')
        },
        // {
        //   path: 'profile',
        //   name: 'profile',
        //   component: () => import('../views/Profile.vue')
        // },
    ],
}
