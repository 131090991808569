export default {
    path: '/:communityOwner/:communityName',
    component: () => import('../pages/communityPage/JirutoCommunity'),
    props: true,
    children: [
        {
            path: '',
            name: 'dummyCommunity',
            redirect: {name: 'allBoards'},
            props: true,
            // meta: {
            //   requiresVerified: true,
            // },
        },

        {
            path: 'boards',
            component: () => import('../pages/communityPage/CommunityBoards.vue'),
            props: true,
            children: [
                {
                    path: '',
                    name: 'allBoards',
                    component: () => import('../pages/communityPage/CommunityBoardsMain'),
                    props: true,
                    // meta: {
                    //   requiresVerified: true,
                    // },
                },
                {
                    path: 'board/:boardID',
                    name: 'communityBoard',
                    component: () => import('../pages/communityPage/CommunityBoard.vue'),
                    props: true,
                    // meta: {
                    //   requiresVerified: true,
                    // },
                },
            ]
            // meta: {
            //   requiresVerified: true,
            // },
        },
        {
            path: 'collaborators',
            name: 'collaborators',
            component: () => import('../pages/communityPage/CommunityCollaborators.vue'),
            props: true,
        },
        {
            path: 'learners',
            name: 'learners',
            component: () => import('../views/Learners.vue'),
            props: true,
        },
        {
            path: 'subscribers',
            name: 'subscribers',
            component: () => import('../views/Subscribers.vue'),
            props: true,
        },
        {
            path: 'discussion',
            // name: 'discussion',
            component: () => import('../pages/communityPage/communityForum/CommunityForum'),
            props: true,

            children: [
                {
                    path: '',
                    name: 'forumHome',
                    component: () => import('../pages/communityPage/communityForum/ForumPosts'),
                    props: true,
                },
                {
                    path: 'newPost',
                    name: 'newPost',
                    component: () => import('../pages/communityPage/communityForum/NewPost'),
                    props: true,
                },
                {
                    path: 'posts/:postID',
                    name: 'forumPost',
                    component: () => import('../pages/communityPage/communityForum/ForumPost'),
                    props: true,
                },
            ]
        },
        {
            path: 'educators',
            name: 'educators',
            component: () => import('../unused/CommunityEducators.vue'),
            props: true,

        },
        {
            path: 'groupSessions',
            name: 'communityGroupSessions',
            component: () => import('../pages/communityPage/CommunityGroupSessions.vue'),
            props: true,
        },
        {
            path: 'settings',
            // name: 'settings',
            component: () => import('../pages/communityPage/CommunitySettingsMain'),
            props: true,
            meta: {
                requiresVerified: true,
            },
            children: [
                {
                    path: '',
                    name: 'communitySettingsMain',
                    redirect: {name: 'communitySettingsGeneral'}
                },
                {
                    path: 'general',
                    name: 'communitySettingsGeneral',
                    component: () => import('../pages/communityPage/CommunitySettingsGeneral'),
                    props: true,
                },
                {
                    path: 'integrations',
                    name: 'communitySettingsIntegrations',
                    component: () => import('../pages/communityPage/CommunitySettingsIntegrations'),
                    props: true,
                },
                {
                    path: 'delete',
                    name: 'communitySettingsDelete',
                    component: () => import('../pages/communityPage/CommunitySettingsDelete'),
                    props: true,
                },
                {
                    path: 'collaborators',
                    name: 'communitySettingsCollaborators',
                    component: () => import('../pages/communityPage/CommunitySettingsCollaborators'),
                    props: true,
                },
            ]
        },
    ],
}
