
export default {
    path: '/my',
    component: () => import('../views/MyPages.vue'),
    children: [
        {
            path: '',
            name: 'dummyMy',
            redirect: {name: 'myDashboard'}
        },
        {
            path: 'dashboard',
            name: 'myDashboard',
            component: () => import('../views/Dashboard.vue'),
            // redirect: {name: 'accountSettings'}
        },
        {
            path: 'groupSessions',
            name: 'groupSessions',
            component: () => import('../views/MyGroupSessions.vue')
        },
        {
            path: 'savedItems',
            name: 'savedItems',
            component: () => import('../views/MySavedItems.vue')
        },
        {
            path: 'calendar',
            name: 'calendar',
            component: () => import('../views/MyCalendar.vue')
        },
    ],
}
