<template>

  <div id="app" class="relative h-screen w-screen bg-jiruto-main">

      <div class="w-full ">
        <mainNavBar v-on:search="search"/>
      </div>

      <div class="flex w-full h-full relative z-20 lg:top-20">
        <div v-if="$store.state.jirutoUser.username !== null" class="z-10">
          <sideMenu v-if="isSignedIn"/>
        </div>

        <div class="flex justify-center w-full relative z-5" :class="{'lg:px-64': $store.state.jirutoUser.username !== null}">
            <div class="lg:hidden absolute left-2 top-2">
                <div v-if="!showSideMenu">
                    <button @click="showSideMenuPressed" class="text-jiruto-zotMain hover:text-jiruto-zotDarker rounded-full bg-gray-200 p-2 hover:bg-gray-100  border-0">
                        <svg class="w-6 h-6 " fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"/></svg>
                    </button>
                </div>
            </div>
            <router-view class="w-full h-full" :queryProp="searchTerm"/>
        </div>



        <div v-if="$store.state.jirutoUser.username !== null" class="z-10">
          <notification-menu v-if="isSignedIn"/>
        </div>
      </div>
  </div>
</template>


<script>
  import SideMenu from "./components/SideMenu"
  import NotificationMenu from "./components/NotificationMenu"
  import * as fb from './firebaseConfig'
  import MainNavBar from "./components/MainNavBar"

  // const fb = require ('./firebaseConfig')
  // import JirutoFooter from "./components/JirutoFooter"
  // import TopNavBar from "./components/TopNavBar";

  export default {
      data() {
          return {
              searchTerm: "",
              showSideMenu: false,
              showNotificationMenu: false,
          }
      },
      metaInfo(){
          return{
              title: "Jiruto: learn anything",
              meta: [
                  {vmid: 'description', property: "description", name:'description', content: "Find the best resources. Rely on discussion boards. Participate in learning groups."},
                  {vmid: 'og:title', property: "og:title", name:'ogTitle', content: "Jiruto - The Open Ed, Social Learning Platform"},
                  {vmid: "og:image", property: "og:image", name:'ogImage', content: "https://firebasestorage.googleapis.com/v0/b/jiruto-1bee2.appspot.com/o/public%2Fassets%2FFull%20Logo.png?alt=media&token=89799e7a-470d-4187-8ede-d4f5659d8db0"},
                  {vmid: 'og:site_name', property: "og:site_name", name:'siteName', content: "Jiruto"},
                  {vmid: 'og:description', property: "og:description", name:'ogDescription', content: "Find the best resources. Rely on discussion boards. Participate in learning groups."},

                  {vmid: 'twitter:title', name: "twitter:title", content: "Jiruto - The Open Ed, Social Learning Platform"},
                  {vmid: "twitter:image", name: "twitter:image", content: "https://firebasestorage.googleapis.com/v0/b/jiruto-1bee2.appspot.com/o/public%2Fassets%2FFull%20Logo.png?alt=media&token=89799e7a-470d-4187-8ede-d4f5659d8db0"},
                  {vmid: 'twitter:site_name', name: "twitter:site_name", content: "Jiruto"},
                  {vmid: 'twitter:card', name: "twitter:card", content: "summary_large_image"},
                  {vmid: 'twitter:description', name: "twitter:description", content: "Find the best resources. Rely on discussion boards. Participate in learning groups."},
              ]
          }
      },
      // metaInfo: {
      //     // if no subcomponents specify a metaInfo.title, this title will be used
      //     title: 'Default Title',
      //     // all titles will be injected into this template
      //     titleTemplate: '%s | My Awesome Webapp'
      // },
      mounted(){
          // if (fb.auth.currentUser){
          //     window.Intercom("boot", {
          //         app_id: "knkp7n5t",
          //         name: this.$store.getters.getUsername, // Full name
          //         email: fb.auth.currentUser.email, // Email address
          //         signed_up_at: this.$store.getters.getUser.createdAt // Signup date as a Unix timestamp
          //     });
          //     // window.heap.identify('this.$store.getters.getUsername')
          //     // window.heap.addUserProperties({'Name': this.$store.getter.getFirstName, 'Profession': 'Data Scientist'});
          // } else {
          //     window.Intercom("boot", {
          //         app_id: "knkp7n5t"
          //     });
          // }

      },
      components: {
          // topNavBar: TopNavBar,
          // jirutoFooter: JirutoFooter,
          mainNavBar: MainNavBar,

          sideMenu: SideMenu,
          notificationMenu: NotificationMenu,
      },
      methods: {
          showSideMenuPressed(){
              console.log("ShowSideMenu pressed")
          },
          search(term){
              this.searchTerm = term
          },
      },
      computed: {
          isSignedIn(){
              return !!fb.auth.currentUser
          },
          currentUser(){
              return fb.auth.currentUser
          },
      }
  }
</script>

<style src="@/assets/styles/tailwind.css" />

<style >
body{
  @apply bg-jiruto-main
}

/* Remove outline for non-keyboard :focus */
:focus {
    outline: none !important;
}

/* Optional: Customize .focus-visible */
:focus-visible {
    //outline: #CEBDF3 solid 2px !important;
}

</style>
