// import * as store from './store/index'
import axios from 'axios'
import * as fb from './firebaseConfig'

const productionURL = 'https://jigo.jiruto.com'
const tryURL = 'https://jigo.try.jiruto.com'
const testURL = 'http://localhost:8888'
// const testURL = 'http://10.0.1.10:8888'

// let baseURL =  process.env.NODE_ENV === 'production' ? productionURL : testURL

let baseURL = () => {
    // const envVarMode = process.env.NODE_ENV
    const envVarMode = process.env.VUE_APP_DEPLOY_MODE

    console.log("envVar is", envVarMode)
    if (envVarMode === 'production') {
        return productionURL
    } else if (envVarMode === 'staging') {
        return tryURL
    } else {
        return testURL
    }
}

const jigo = axios.create({
    baseURL: baseURL(),
    headers: {
        'Content-Type': 'application/json',
    },
});

jigo.interceptors.request.use((config) => {
    // const authorization = 'token ' + store.getters.getToken
    if (fb.auth.currentUser) {
        return fb.auth.currentUser.getIdToken(true).then((idToken) => {
            config.headers.Authorization = 'Bearer ' + idToken;
            // console.log("token is", idToken)
            // console.log("Baseurl is", baseURL())
            return config
        }).catch((err) =>{
            console.error("An error occurred authorizing the request", err)
            return config
        })
    } else {
        return config
    }

}, (error) => {
    return Promise.reject(error)
});


const clean = axios.create()

export {
    // jigit,
    jigo,
    clean,
    // jicourse,
}





// const jigit = axios.create({
//     baseURL: 'https://jigit.jiruto.com/api/v1',
//     responseType: 'json',
//     headers: {
//         'Content-Type': 'application/json',
//     },
// });

// jigit.interceptors.request.use((config) => {
//     // const authorization = 'token ' + store.getters.getToken
//     // config.headers.Authorization = authorization
//
//     return config
// }, (error) => {
//     return Promise.reject(error)
// });



// const jicourse = axios.create({
//     baseURL: 'https://jicourse.jiruto.com',
//
//     headers: {
//         'Content-Type': 'application/json',
//         // 'Api-Username': store.getters.getUsername,
//         // 'Api-Key': store.getters.getTokens.jicourseToken,
//     },
// });
//
// // console.log(store)
// // jicourse.defaults.headers.common['Api-Username'] = store.getters.getUsername
// // jicourse.defaults.headers.common['Api-Key'] = store.getters.getTokens.jicourseToken
//
// jicourse.interceptors.request.use((config) => {
//     // const apiKey = 'token ' + fb.auth.currentUser.uid
//     // const apiUsername = 'token ' + store.getters.getUsername
//     // console.log("Store is:", store)
//     // config.headers.setHeader("Api-Key", apiKey)
//     // console.log("Jicourse Headers:", config.headers)
//     // config.headers.add("Api-Key", apiKey)
//
//     // config.headers.setHeader("Api-Username", apiUsername)
//
//     return config
// }, (error) => {
//     return Promise.reject(error)
// });
