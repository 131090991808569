import Vue from 'vue'
import Vuex from 'vuex'
import community from './modules/community'
import {saveStatePlugin} from "../utils";

Vue.use(Vuex)


import * as fb from '../firebaseConfig.js'
import * as axios from '../axios-auth.js'
export default new Vuex.Store({
    state: {
        plugins: [saveStatePlugin],
        jirutoUser: {
            JID: null,
            jigitToken: null,
            username: null,
            avatar_url: null,
            premium: null,
            subscription: null,
            firstName: null,
            lastName: null,
        },
        tokens: {
            jicourseToken: null,
            jighostToken: null,
        },
        communities: [],
        searchTerm: "",
    },
    getters: {
        getContent(state) {
            return (id) => {
                for (const board of state.boards) {
                    for (const content of board.jirutoContent) {
                        if (content.contentId == id) {
                            return content
                        }
                    }
                }
            }
        },
        isPremium(state){
            return state.jirutoUser.premium
        },
        isAuthenticated(state){
            return state.jirutoUser.jigitToken !== null
        },
        getToken(state){
            return state.jirutoUser.jigitToken
        },
        getUsername(state) {
            return state.jirutoUser.username
        },
        getAvatarUrl(state){
            return state.jirutoUser.avatar_url
        },
        getUser(state){
            return state.jirutoUser
        },
        getSubscription(state){
            return state.jirutoUser.subscription
        },
        getCommunities(state) {
            return state.communities
        },
        getSearchTerm(state){
            return state.searchTerm
        },
        getFirstName(state){
            return state.jirutoUser.firstName
        },
        getLastName(state){
            return state.jirutoUser.lastName
        },
        getFullName(state){
            return `${state.jirutoUser.firstName} ${state.jirutoUser.lastName}`
        },
        getTokens(state){
            return state.tokens
        }
    },
    mutations: {
        authUser (state, userData) {
            state.jirutoUser = userData
        },
        setCommunities(state, communities) {
            state.communities = communities
        },
        setSearchTerm(state, term) {
            state.searchTerm = term
        },
        setAvatarUrl(state, avatarUrl){
            state.jirutoUser.avatar_url = avatarUrl
        },
        setTokens(state, tokens) {
            state.tokens = tokens
        },
        setFirstName(state, firstName){
            state.jirutoUser.firstName = firstName
        },
        setLastName(state, lastName){
            state.jirutoUser.lastName = lastName
        },
        setUsername(state, username) {
            state.jirutoUser.username = username
        },
        setFullName(state, payload) {
            state.jirutoUser.firstName = payload.firstName
            state.jirutoUser.lastName = payload.lastName
        }
    },
    /* eslint no-console: ["error", { allow: ["warn", "error", "log"] }] */
    actions: {
        updateName(context, payload) {
            context.commit('setFullName', payload)
        },
        updateUsername(context, username) {
            context.commit('setUsername', username)
        },
        updateAvatarUrl(context, avatarUrl){
            context.commit('setAvatarUrl', avatarUrl)
        },
        setSearchTerm(context, term){
            context.commit('setSearchTerm', term)
        },
        signup (context, authData) {
            return new Promise((resolve, reject) => {
                axios.jigo.post('/v2/newUser', authData)
                    .then(res => {
                        if (res.status === 201) {
                            context.dispatch('signin',{email: authData.email, password: authData.password}).then(() => {
                                context.dispatch('sendVerificationEmail').then(resolve).catch(err => reject(err))
                            }).catch(err => reject(err))
                        } else {
                            console.error("unknown error")
                            reject(res.data)
                        }
                    }).catch((err) => {
                    console.error("An Error Has occurred", err)
                    reject(err)
                })
            })
        },
        signin(context ,{email, password}){
            console.log("Email password", email, password)
            return new Promise((resolve, reject) => {
                fb.auth.signInWithEmailAndPassword(email, password).then((user) => {
                    console.log("Login Success!!", user)
                    context.dispatch('getUserData').then(() => {
                        console.log("Success!", user)
                        resolve(user)
                    }).catch(err => {
                        console.log("Get User Data failed: ", err)
                        reject(err)
                    })
                }).catch((err) => {
                    reject(err)
                })
            })
        },
        sendVerificationEmail(){
            return new Promise((resolve, reject) => {
                var user = fb.auth.currentUser
                if(user) {
                    user.sendEmailVerification().then(() => {
                        resolve()
                    }).catch(error => {
                        console.error("Error while sending verification email: ", error)
                        reject()
                    });
                } else {
                    reject()
                }
            })
        },
        setUserData(context){
            if(fb.auth.currentUser){
                const jigitToken = localStorage.getItem("jigitToken")
                const username = localStorage.getItem("username")
                const avatar_url = localStorage.getItem('avatar_url')
                const premium = localStorage.getItem('premium')
                const subscription = localStorage.getItem('subscription')
                //DEBUG
                if (jigitToken && username) {
                    //DEBUG
                    context.commit('authUser', {jigitToken: jigitToken, username: username,
                        avatar_url: avatar_url, subscription: subscription, premium: premium})
                } else {
                    context.dispatch('getUserData')
                }
            } else {
                context.dispatch('signOut')
            }
        },
        getUserData(context){
            return new Promise((resolve, reject) => {
                var user = fb.auth.currentUser

                if (user) {
                    axios.jigo.get("/v2/user").then((resp) => {
                        if (resp.status === 200) {
                            const data = resp.data
                            let userData = {
                                JID: data.JID,
                                username: data.username,
                                avatar_url: data.avatarUrl,
                                premium: data.premium,
                                firstName: data.firstName,
                                lastName: data.lastName
                            }
                            localStorage.setItem('username', userData.username)
                            localStorage.setItem('avatar_url', userData.avatar_url)
                            localStorage.setItem('premium', userData.premium)
                            localStorage.setItem('subscription', userData.subscription)

                            context.commit('authUser', userData)
                            resolve(userData)
                            // context.dispatch('getUserTokens').then(
                            //     resolve(userData)
                            // ).catch((err) => reject(err))
                        } else {
                            // doc.data() will be undefined in this case
                            console.error("Document doesn't exist");
                            reject("document doesn't exist!")
                        }
                    }).catch(err => {
                        console.error("Couldn't retrieve document", err)
                        reject(err)
                    })
                } else {
                    reject("user isn't logged")
                }
            })
        },
        signOut(context){
            return new Promise((resolve, reject) => {
                fb.auth.signOut().then(() => {
                    context.commit('authUser', {jigitToken: null, username: null})
                    // localStorage.removeItem('username')
                    // localStorage.removeItem('jigitToken')
                    // localStorage.removeItem('avatar_url')
                    localStorage.clear()
                    resolve()
                }).catch((err) => {
                    console.error("Error while signing out from the Firebase!")
                    reject(err)
                })
            })
        },
    },
    modules: {
        community
    }
})
